<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="手机号码" name="mobilePhone">
          <a-input v-model:value="form.mobilePhone" :maxlength="11"/>
        </a-form-item>
        <a-form-item label="姓名" name="realName">
          <a-input v-model:value="form.realName"/>
        </a-form-item>
        <a-form-item label="性别" name="gender">
          <a-select v-model:value="form.gender">
            <a-select-option v-for="item in dictData.genderData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="身份证号" name="cardNo">
          <a-input v-model:value="form.cardNo"/>
        </a-form-item>
        <a-form-item label="角色" name="roleId">
          <a-select v-model:value="form.roleId">
            <a-select-option v-for="item in dictData.roleData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

export default {
  name: 'OperatorModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {
        operatorId: null,
        mobilePhone: "",
        realName: "",
        gender: null,
        cardNo: "",
        roleId: null
      },
      rules: {
        mobilePhone: [
          {required: true, message: '请输入手机号码！', trigger: 'blur'},
        ],
        realName: [
          {required: true, message: '请输入姓名！', trigger: 'blur'},
        ],
        roleId: [
          {required: true, message: '请选择角色！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_operator_config");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';
      this.form = {...record};
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.operator.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>