<template>
  <div class="login">
    <a-form ref="form" :model="form" :rules="rules" class="login-form" @finish="handleSubmit">
      <a-form-item>
        <div class="login-title">管理后台</div>
      </a-form-item>
      <a-form-item name="operatorName" class="login-field">
        <a-input v-model:value="form.operatorName" placeholder="用户名" class="login-input" :maxlength="11">
          <template #prefix>
            <UserOutlined style="color:rgba(0,0,0,.25)"/>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item name="password" class="login-field">
        <a-input v-model:value="form.password" type="password" placeholder="密码" class="login-input">
          <template #prefix>
            <LockOutlined style="color:rgba(0,0,0,.25)"/>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item class="login-field login-submit">
        <a-button type="primary" :loading="loading" html-type="submit" class="login-input">登录</a-button>
      </a-form-item>
    </a-form>
    <div class="login-footer">江汉妇幼保健院</div>
  </div>
</template>

<script>
import CommonMixin from '@/mixin/admin/CommonMixin';
import api from "@/api/admin";
import constant from '@/store/admin/constant';

export default {
  name: 'Login',
  mixins: [CommonMixin],
  data() {
    return {
      loading: false,
      form: {
        operatorName: '',
        password: '',
      },
      rules: {
        operatorName: [{required: true, message: "请输入用户名！", trigger: "blur"}],
        password: [{required: true, message: "请输入密码！", trigger: "blur"}],
      },
    };
  },
  methods: {
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.loading = true;
      api.login(that.formData()).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }

        // 登录成功
        that.$store.commit("SET_LOGIN_DATA", res.data);
        sessionStorage.setItem(constant.LOGIN_DATA, JSON.stringify(res.data));
      }).catch(() => {
        that.loading = false;
        that.$message.error('请求异常！');
      });
    },
  },
};
</script>

<style scoped>
.login {
  position: relative;
  height: 100%;
  background-image: url('~@/assets/login_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #ffffff;
  width: 500px;
  border-radius: 10px;
}

.login-title {
  text-align: center;
  font-size: 30px;
  color: #1890ff;
  padding: 30px 0;
}

.login-field {
  text-align: center;
}

.login-input {
  width: 300px;
}

.login-submit {
  padding: 30px 0;
}

.login-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  padding-bottom: 15px;
}
</style>
