<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="预约挂号时间" name="timeRange">
        <a-range-picker v-model:value="form.timeRange" format="YYYY-MM-DD" style="width:220px">
          <template #suffixIcon>
            <CalendarOutlined/>
          </template>
        </a-range-picker>
      </a-form-item>
      <a-form-item label="科室" name="departmentId">
        <a-tree-select
            v-model:value="form.departmentId"
            style="width: 200px;"
            :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
            placeholder=""
            allow-clear
            :tree-data="departmentData"
            :field-names="{label: 'name',value: 'id',children: 'children'}">
        </a-tree-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="handleExport" :loading="spinning">
          导出
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

      </a-table>
    </div>

  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

const columns = [
  {
    title: '挂号编号',
    dataIndex: 'code',
    width: 120,
    align: 'center',
  },
  {
    title: '就诊人姓名',
    dataIndex: 'visitorName',
    width: 100,
    align: 'center',
  },
  {
    title: '就诊人身份证号',
    dataIndex: 'visitorNumber',
    width: 150,
    align: 'center',
  },
  {
    title: '就诊人手机号',
    dataIndex: 'visitorMobilePhone',
    width: 120,
    align: 'center',
  },
  {
    title: '亲属姓名',
    dataIndex: 'familyName',
    width: 100,
    align: 'center',
  },
  {
    title: '亲属身份证号',
    dataIndex: 'familyNumber',
    width: 150,
    align: 'center',
  },
  {
    title: '亲属手机号',
    dataIndex: 'familyMobilePhone',
    width: 120,
    align: 'center',
  },
  {
    title: '接诊科室',
    dataIndex: 'departmentName',
    width: 150,
    align: 'center',
  },
  {
    title: '挂号成功时间',
    dataIndex: 'registerSuccessTime',
    width: 150,
    align: 'center',
  },
  {
    title: '预约挂号时间',
    dataIndex: 'appointmentRegisterTime',
    width: 180,
    align: 'center',
  },
  {
    title: '签到时间',
    dataIndex: 'signTime',
    width: 150,
    align: 'center',
  },
  {
    title: '实际就诊时间',
    dataIndex: 'actualVisitTime',
    width: 150,
    align: 'center',
  },
];

export default {
  name: 'RegisterExportList',
  mixins: [CommonMixin],
  components: {},
  created() {
    this.loadData();
    this.loadDepartment();
  },
  data() {
    return {
      departmentData: [],
      form: {
        timeRange: null,
        departmentId: null,
      },
      columns,
      dataSource: [],
      loading: false,
      spinning: false
    }
  },
  methods: {
    loadDepartment() {
      let that = this;
      api.department.tree().then(res => {
        if (res.code != that.apiStatus.success) {
          return;
        }
        that.departmentData = res.data;
      });
    },
    formData() {
      let data = {...this.form};
      if (data.timeRange && data.timeRange.length > 0 && data.timeRange[0] && data.timeRange[1]) {
        data.queryDate = data.timeRange[0].format("YYYY-MM-DD") + "~" + data.timeRange[1].format("YYYY-MM-DD");
      }
      return data;
    },
    loadData(pageNum) {
      let that = this;
      let parm = that.formData();
      if (pageNum) {
        parm.pageNum = pageNum;
        that.pagination.current = pageNum;
      } else {
        parm.pageNum = that.pagination.current;
      }
      parm.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.register.registerExportList(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData(1);
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleExport() {
      let that = this;
      that.$confirm({
        title: '确定导出？',
        content: '',
        onOk: () => {
          that.spinning = true;
          api.register.registerExportData(that.formData()).then(res => {
            that.spinning = false;
            if (res.code != that.apiStatus.success) {
              that.$message.error(res.message);
              return;
            }
            that.$message.success(that.apiStatus.defaultSuccMsg);
            window.open(res.data);
          }).catch(() => {
            that.spinning = false;
            that.$message.error(that.apiStatus.defaultErrMsg);
          });
        }
      });
    },
  }
}
</script>
