<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="就诊人姓名" name="visitorName">
        <a-input v-model:value="form.visitorName" class="query-condition"/>
      </a-form-item>
      <a-form-item label="挂号编号" name="code">
        <a-input v-model:value="form.code" class="query-condition"/>
      </a-form-item>
      <a-form-item label="科室" name="departmentId">
        <a-tree-select
            v-model:value="form.departmentId"
            style="width: 200px;"
            :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
            placeholder=""
            allow-clear
            :tree-data="departmentData"
            :field-names="{label: 'name',value: 'id',children: 'children'}">
        </a-tree-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <span v-if="record.status === 30">已就诊</span>
            <a-popconfirm v-else title="确认就诊?" @confirm="() => handleVisit(record)">
              <a>就诊</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>
    </div>

  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

const columns = [
  {
    title: '挂号编号',
    dataIndex: 'code',
    width: 120,
    align: 'center',
  },
  {
    title: '就诊人姓名',
    dataIndex: 'visitorName',
    width: 100,
    align: 'center',
  },
  {
    title: '就诊人身份证号',
    dataIndex: 'visitorNumber',
    width: 150,
    align: 'center',
  },
  {
    title: '就诊人手机号',
    dataIndex: 'visitorMobilePhone',
    width: 120,
    align: 'center',
  },
  {
    title: '亲属姓名',
    dataIndex: 'familyName',
    width: 100,
    align: 'center',
  },
  {
    title: '亲属身份证号',
    dataIndex: 'familyNumber',
    width: 150,
    align: 'center',
  },
  {
    title: '亲属手机号',
    dataIndex: 'familyMobilePhone',
    width: 120,
    align: 'center',
  },
  {
    title: '接诊科室',
    dataIndex: 'departmentName',
    width: 150,
    align: 'center',
  },
  {
    title: '挂号成功时间',
    dataIndex: 'registerSuccessTime',
    width: 150,
    align: 'center',
  },
  {
    title: '预约挂号时间',
    dataIndex: 'appointmentRegisterTime',
    width: 180,
    align: 'center',
  },
  {
    title: '实际就诊时间',
    dataIndex: 'actualVisitTime',
    width: 150,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
];

export default {
  name: 'VisitList',
  mixins: [CommonMixin],
  components: {},
  created() {
    this.loadData();
    this.loadDepartment();
  },
  data() {
    return {
      departmentData: [],
      form: {
        visitorName: '',
        code: '',
        departmentId: null,
      },
      columns,
      dataSource: [],
      loading: false,
    }
  },
  methods: {
    loadDepartment() {
      let that = this;
      api.department.tree().then(res => {
        if (res.code != that.apiStatus.success) {
          return;
        }
        that.departmentData = res.data;
      });
    },
    loadData(pageNum) {
      let that = this;
      if (pageNum) {
        that.form.pageNum = pageNum;
        that.pagination.current = pageNum;
      } else {
        that.form.pageNum = that.pagination.current;
      }
      that.form.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.register.registerList(that.form).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    searchQuery() {
      this.loadData(1);
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    handleVisit(record) {
      let that = this;
      api.register.visit(record.id).then((res) => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
}
</script>
