<template>
  <a-modal v-model:visible="visible" title="修改密码" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item name="password" label="旧密码">
          <a-input v-model:value="form.password" placeholder="请输入旧密码" type="password"/>
        </a-form-item>
        <a-form-item name="newPassword" label="新密码">
          <a-input v-model:value="form.newPassword" placeholder="请输入新密码" type="password"/>
        </a-form-item>
        <a-form-item name="confirmPassword" label="确认新密码">
          <a-input v-model:value="form.confirmPassword" placeholder="请再次输入新密码" type="password"/>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="save" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import constant from "@/store/admin/constant";

export default {
  name: 'UpdatePassword',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      form: {
        operatorId: null,
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        password: [
          {required: true, message: "请输入旧密码"},
        ],
        newPassword: [
          {required: true, message: "请输入新密码"},
        ],
        confirmPassword: [
          {required: true, message: "请重新输入新密码"},
        ],
      },
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    edit(operatorId) {
      this.visible = true;
      this.form.operatorId = operatorId;
    },
    save() {
      let that = this;
      that.$refs.form.validate().then(() => {
        if (that.form.newPassword != that.form.confirmPassword) {
          that.$message.error('新密码与确认密码不一致！');
          return;
        }

        that.spinning = true;
        api.operator.updatePassword(that.form).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success("修改成功，请重新登录");
          that.close();
          that.$store.commit("CLEAR_LOGIN_DATA");
          sessionStorage.removeItem(constant.LOGIN_DATA);
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    },

  }
}
</script>