const app = {
    state: {
        // 接口返回状态
        apiStatus: {
            fail: 0,
            success: 1,
            invalidToken: 2,
            defaultErrMsg: '操作异常！',
            defaultSuccMsg: '操作成功！'
        },
        // 分页对象
        pagination: {
            current: 1,
            pageSize: 20,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            showTotal: total => `总共${total}条`,
            total: 0
        },
        // 编辑器
        quillEditorOption: {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{'header': 1}, {'header': 2}],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'script': 'sub'}, {'script': 'super'}],
                    [{'indent': '-1'}, {'indent': '+1'}],
                    [{'direction': 'rtl'}],
                    [{'size': ['small', false, 'large', 'huge']}],
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'color': []}, {'background': []}],
                    [{'font': []}],
                    [{'align': []}],
                    ['clean']
                ]
            }
        }
    },
    mutations: {},
    actions: {}
};

export default app;