<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="项目名称" name="name">
          <a-input v-model:value="form.name" maxlength="20"/>
        </a-form-item>
        <a-form-item label="项目描述" name="description">
          <quill-editor v-model:value="form.description" :options="quillEditorOption" @change="onEditorChange($event)">
          </quill-editor>
        </a-form-item>
        <a-form-item label="费用" name="money">
          <a-input v-model:value="form.money"/>
        </a-form-item>
        <a-form-item label="科室" name="departmentId">
          <a-tree-select
              v-model:value="form.departmentId"
              :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
              placeholder=""
              allow-clear
              :tree-data="departmentData"
              :field-names="{label: 'name',value: 'id',children: 'children'}">
          </a-tree-select>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

export default {
  name: 'ExamItemModel',
  mixins: [CommonMixin],
  components: {},
  watch: {
    visible() {
      let that = this;
      if (that.visible) {
        that.loadDepartment();
      }
    }
  },
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      quillEditorOption: this.$store.state.app.quillEditorOption,
      departmentData: [],
      form: {
        examItemId: null,
        name: '',
        description: '',
        money: '',
        departmentId: null,
      },
      rules: {
        name: [
          {required: true, message: '请输入项目名称！', trigger: 'blur'},
        ],
        money: [
          {required: true, message: '请输入费用！', trigger: 'blur'},
        ],
        departmentId: [
          {required: true, message: '请选择科室！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_exam_item_list");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    onEditorChange({html}) {
      this.form.description = html;
    },
    loadDepartment() {
      let that = this;
      api.department.tree().then(res => {
        if (res.code != that.apiStatus.success) {
          return;
        }
        that.departmentData = res.data;
      });
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';
      this.form = {...record};
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.exam.item.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>
