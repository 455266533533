<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="日期" name="date">
        <a-date-picker v-model:value="form.date"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="addTimeConfig">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'amTime'">
            <div v-for="item in record.amTimeList" :key="item.id">
              {{ item.text }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'pmTime'">
            <div v-for="item in record.pmTimeList" :key="item.id">
              {{ item.text }}
            </div>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a @click="editTimeConfig(record)">编辑</a>
            <a-popconfirm v-if="record.type != 1" title="确定删除?" @confirm="() => deleteTimeConfig(record.id)">
              <a-divider type="vertical"/>
              <a>删除</a>
            </a-popconfirm>
          </template>
        </template>

      </a-table>

      <TimeModel ref="timeModel" @ok="loadData"/>
    </div>

  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import TimeModel from "./TimeModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '模式',
    dataIndex: 'mode',
    width: 100,
    align: 'center',
  },
  {
    title: '日期',
    dataIndex: 'date',
    width: 100,
    align: 'center',
  },
  {
    title: '上午时间段',
    dataIndex: 'amTime',
    width: 100,
    align: 'center',
  },
  {
    title: '下午时间段',
    dataIndex: 'pmTime',
    width: 100,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    align: 'center',
  },
];

export default {
  name: 'TimeConfig',
  mixins: [CommonMixin],
  components: {
    TimeModel,
  },
  data() {
    return {
      form: {
        date: null,
      },
      columns,
      visible: false,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    formData() {
      let data = {...this.form};
      data.date = data.date ? data.date.format("YYYY-MM-DD") : null;
      return data;
    },
    loadData() {
      let that = this;
      let param = that.formData();
      param.pageNum = that.pagination.current;
      param.pageSize = that.pagination.pageSize;
      that.loading = true;
      api.exam.timeConfig.list(param).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        res.data.list.forEach(function (data) {
          // 上午时间段
          let amTimeList = [];
          if (data.amTime) {
            data.amTime.split(";").forEach(function (item) {
              let ss = item.split(",");
              amTimeList.push({
                id: 0,
                text: ss[0] + " " + ss[1] + "人"
              });
            });
          }
          data.amTimeList = amTimeList;
          // 下午时间段
          let pmTimeList = [];
          if (data.pmTime) {
            data.pmTime.split(";").forEach(function (item) {
              let ss = item.split(",");
              pmTimeList.push({
                id: 0,
                text: ss[0] + " " + ss[1] + "人"
              });
            });
          }
          data.pmTimeList = pmTimeList;
        });
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    addTimeConfig() {
      this.$refs.timeModel.add();
    },
    editTimeConfig(record) {
      this.$refs.timeModel.edit(record);
    },
    deleteTimeConfig(id) {
      let that = this;
      api.exam.timeConfig.delete(id).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
