import {axios} from './axios';

const postAction = (url, parm, timeout) => {
    let config = {
        url: url,
        method: 'post',
        data: parm ? parm : {}
    };
    if (timeout) {
        config.timeout = timeout;
    }
    return axios(config);
};

export default {
    // 登录
    login: (parm) => postAction("/api/sys/login", parm),
    // 退出
    logout: () => postAction('/api/sys/logout'),

    // 后台操作员
    operator: {
        list: (parm) => postAction("/api/admin/operator/list", parm),
        save: (parm) => postAction("/api/admin/operator/save", parm),
        enable: (operatorId) => postAction("/api/admin/operator/enable/" + operatorId),
        disable: (operatorId) => postAction("/api/admin/operator/disable/" + operatorId),
        resetPassword: (operatorId) => postAction("/api/admin/operator/resetPassword/" + operatorId),
        updatePassword: (parm) => postAction("/api/admin/operator/updatePassword", parm),
    },

    // 挂号
    register: {
        registerList: (parm) => postAction("/api/admin/register/registerList", parm),
        signIn: (id) => postAction("/api/admin/register/signIn/" + id),
        visitList: (parm) => postAction("/api/admin/register/visitList", parm),
        visit: (id) => postAction("/api/admin/register/visit/" + id),
        registerExportList: (parm) => postAction("/api/admin/register/registerExportList", parm),
        registerExportData: (parm) => postAction("/api/admin/register/registerExportData", parm),
        timeConfig: {
            list: (parm) => postAction("/api/admin/register/timeConfig/list", parm),
            save: (parm) => postAction("/api/admin/register/timeConfig/save", parm),
            delete: (id) => postAction("/api/admin/register/timeConfig/delete/" + id),
        },
    },

    // 科室
    department: {
        tree: () => postAction('/api/admin/department/tree'),
        save: (parm) => postAction("/api/admin/department/save", parm),
        delete: (id) => postAction("/api/admin/department/delete/" + id),
    },

    // 体检
    exam: {
        item: {
            list: (parm) => postAction("/api/admin/exam/item/list", parm),
            save: (parm) => postAction("/api/admin/exam/item/save", parm),
            validData: () => postAction("/api/admin/exam/item/validData"),
        },
        suite: {
            list: (parm) => postAction("/api/admin/exam/suite/list", parm),
            save: (parm) => postAction("/api/admin/exam/suite/save", parm),
            uploadIcon: "/api/admin/exam/suite/uploadIcon",
        },
        timeConfig: {
            list: (parm) => postAction("/api/admin/exam/timeConfig/list", parm),
            save: (parm) => postAction("/api/admin/exam/timeConfig/save", parm),
            delete: (id) => postAction("/api/admin/exam/timeConfig/delete/" + id),
        },
        info: {
            list: (parm) => postAction("/api/admin/exam/info/list", parm),
            refundOrder: (parm) => postAction("/api/admin/exam/info/refundOrder", parm),
            uploadReport: "/api/admin/exam/info/uploadReport",
            signIn: (id) => postAction("/api/admin/exam/info/signIn/" + id),
        }
    }
}
