<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="500">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称" name="name">
          <a-input v-model:value="form.name" maxlength="50"/>
        </a-form-item>
        <a-form-item label="位置" name="location">
          <a-input v-model:value="form.location" maxlength="20"/>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

export default {
  name: 'DepartmentModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      form: {
        id: null,
        parentId: null,
        name: ""
      },
      rules: {
        name: [
          {required: true, message: '请输入名称！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_department_config");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    add(parentId) {
      this.visible = true;
      this.title = "新增";
      this.form.parentId = parentId;
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';
      this.form = {...record};
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      return this.form;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.department.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>