import axios from 'axios';
import store from '@/store/admin';
import constant from "@/store/admin/constant";

const instance = axios.create({
    baseURL: window._CONFIG['apiBaseUrl'],
    timeout: 30000
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 请求携带token
    if (store.state.operator.token) {
        config.headers[constant.HEADER_TOKEN] = store.state.operator.token;
    }

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export {
    instance as axios
}
