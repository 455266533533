<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="600">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item v-if="!form.id" label="模式" name="type">
          <a-select v-model:value="form.type" style="width:260px;">
            <a-select-option v-for="item in typeData" :key="item.id" :value="item.id">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="form.id" label="模式" name="mode">
          <a-input v-model:value="form.mode" readonly style="width:260px;"/>
        </a-form-item>
        <a-form-item v-if="form.type != 1" label="日期" name="date">
          <a-date-picker v-model:value="form.date" style="width:260px;"/>
        </a-form-item>
        <a-form-item v-if="form.type != 3" label="上午" name="amRange">
          <div>
            <a class="range-btn" href="javascript:;" @click="addAmRange">新增</a>
          </div>
          <div v-for="(item,index) in form.amRange" :key="index">
            <a-time-range-picker v-model:value="form.amRange[index]" format="HH:mm" style="width:260px;"/>
            <a-input-number v-model:value="form.amNumber[index]" min="1" max="100" style="width:80px;"/>
            <a class="range-btn" href="javascript:;" @click="removeAmRange(index)">删除</a>
          </div>
        </a-form-item>
        <a-form-item v-if="form.type != 3" label="下午" name="pmRange">
          <div>
            <a class="range-btn" href="javascript:;" @click="addPmRange">新增</a>
          </div>
          <div v-for="(item,index) in form.pmRange" :key="index">
            <a-time-range-picker v-model:value="form.pmRange[index]" format="HH:mm" style="width:260px;"/>
            <a-input-number v-model:value="form.pmNumber[index]" min="1" max="100" style="width:80px;"/>
            <a class="range-btn" href="javascript:;" @click="removePmRange(index)">删除</a>
          </div>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import dayjs from 'dayjs';

export default {
  name: 'TimeModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      typeData: [{id: 2, text: "指定工作日"}, {id: 3, text: "指定休息日"}],
      form: {
        id: null,
        date: null,
        amRange: [],
        amNumber: [],
        pmRange: [],
        pmNumber: []
      },
      rules: {
        type: [
          {required: true, message: '请选择模式！', trigger: 'blur'},
        ],
        date: [
          {required: true, message: '请选择日期！', trigger: 'blur'},
        ],
        amRange: [
          {required: true, message: '请选择上午时间段！', trigger: 'blur'},
        ],
        pmRange: [
          {required: true, message: '请选择下午时间段！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_exam_time_config");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';

      let data = {...record};
      // 日期反显
      if (data.date) {
        data.date = dayjs(data.date, "YYYY-MM-DD");
      }

      if (data.amTime) {
        let amRange = [];
        let amNumber = [];
        data.amTime.split(";").forEach(function (line) {
          let ss = line.split(",");
          let at = ss[0].split("~");
          amRange.push([dayjs(at[0].trim(), "HH:mm"), dayjs(at[1].trim(), "HH:mm")]);
          amNumber.push(ss[1]);
        });
        data.amRange = amRange;
        data.amNumber = amNumber;
      }

      if (data.pmTime) {
        let pmRange = [];
        let pmNumber = [];
        data.pmTime.split(";").forEach(function (line) {
          let ss = line.split(",");
          let at = ss[0].split("~");
          pmRange.push([dayjs(at[0].trim(), "HH:mm"), dayjs(at[1].trim(), "HH:mm")]);
          pmNumber.push(ss[1]);
        });
        data.pmRange = pmRange;
        data.pmNumber = pmNumber;
      }

      this.form = data;
    },
    okCallback() {
      this.$emit('ok');
    },
    addAmRange() {
      this.form.amRange.push(null);
      this.form.amNumber.push(10);
    },
    removeAmRange(index) {
      let that = this;
      let amRange = [];
      let amNumber = [];
      that.form.amRange.forEach(function (item, idx) {
        if (idx != index) {
          amRange.push(item);
          amNumber.push(that.form.amNumber[idx]);
        }
      });
      that.form.amRange = amRange;
      that.form.amNumber = amNumber;
    },
    addPmRange() {
      this.form.pmRange.push(null);
      this.form.pmNumber.push(10);
    },
    removePmRange(index) {
      let that = this;
      let pmRange = [];
      let pmNumber = [];
      that.form.pmRange.forEach(function (item, idx) {
        if (idx != index) {
          pmRange.push(item);
          pmNumber.push(that.form.pmNumber[idx]);
        }
      });
      that.form.pmRange = pmRange;
      that.form.pmNumber = pmNumber;
    },
    formData() {
      let data = {...this.form};
      data.date = data.date ? data.date.format("YYYY-MM-DD") : null;

      let amTime = "";
      if (data.amRange && data.amRange.length > 0) {
        data.amRange.forEach(function (item, index) {
          if (index > 0) {
            amTime += ";";
          }
          amTime += item[0].format("HH:mm") + "~" + item[1].format("HH:mm") + "," + (data.amNumber[index] ? data.amNumber[index] : "");
        });
      }
      data.amTime = amTime;

      let pmTime = "";
      if (data.pmRange && data.pmRange.length > 0) {
        data.pmRange.forEach(function (item, index) {
          if (index > 0) {
            pmTime += ";";
          }
          pmTime += item[0].format("HH:mm") + "~" + item[1].format("HH:mm") + "," + (data.pmNumber[index] ? data.pmNumber[index] : "");
        });
      }
      data.pmTime = pmTime;

      return data;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.exam.timeConfig.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>

<style scoped>
.range-btn {
  background-color: #1890ff;
  color: #ffffff;
  font-size: 14px;
  padding: 6px;
  border-radius: 10px;
}
</style>