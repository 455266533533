const operator = {
    state: {
        logged: false,
        token: '',
        operator: {},
        dictData: {},
        menuList: [],
        resourceList: [],
        loginTime: ''
    },

    mutations: {
        SET_LOGIN_DATA: (state, data) => {
            state.token = data.token;
            state.operator = data.operator;
            state.dictData = data.dictData;
            state.menuList = data.menuList;
            state.resourceList = data.resourceList;
            state.loginTime = data.loginTime;
            state.logged = true;
        },
        CLEAR_LOGIN_DATA: (state) => {
            state.logged = false;
            state.token = '';
            state.operator = {};
            state.dictData = {};
            state.menuList = [];
            state.resourceList = [];
            state.loginTime = '';
        },
        SET_TOKEN: (state, data) => {
            state.token = data;
        },
    },

    actions: {}
};

export default operator;