<template>
  <div class="comptent">
    <div class="table-tools">
      <a-button type="primary" @click="addDepartment(0)">
        <template #icon>
          <PlusOutlined/>
        </template>
        新增
      </a-button>
    </div>

    <a-table
        v-if="dataSource.length>0"
        bordered
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        rowKey="id"
        :loading="loading"
        :defaultExpandAllRows="true"
        size="middle">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a @click="editDepartment(record)">编辑</a>
          <a v-if="record.parentId === 0" @click="addDepartment(record.id)">
            <a-divider type="vertical"/>
            添加子科室
          </a>
          <a-popconfirm title="确定删除?" @confirm="() => deleteDepartment(record.id)">
            <a-divider type="vertical"/>
            <a>删除</a>
          </a-popconfirm>
        </template>
      </template>

    </a-table>

    <DepartmentModel ref="departmentModel" @ok="loadData"/>
  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import DepartmentModel from "./DepartmentModel";

const columns = [
  {
    title: '科室名称',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: '位置',
    dataIndex: 'location',
    width: 200,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 200,
    align: 'center'
  },
];

export default {
  name: 'DepartmentConfig',
  mixins: [CommonMixin],
  components: {
    DepartmentModel,
  },
  data() {
    return {
      columns,
      dataSource: [],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      that.loading = true;
      api.department.tree().then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    addDepartment(parentId) {
      this.$refs.departmentModel.add(parentId);
    },
    editDepartment(record) {
      this.$refs.departmentModel.edit(record);
    },
    deleteDepartment(id) {
      let that = this;
      api.department.delete(id).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
