<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称" name="name">
          <a-input v-model:value="form.name" maxlength="20"/>
        </a-form-item>
        <a-form-item label="图标(png)" name="icon">
          <a-upload :action="action" :headers="uploadHeaders" :file-list="fileList" :show-upload-list="false"
                    :before-upload="beforeUpload" @change="handleFileChange">
            <span v-if="form.icon != ''" style="display: inline-block;cursor: pointer;">
              <img :src="form.icon" style="width: 100px;height: 100px;"/>
            </span>
            <div v-else class="avatar-uploader">
              点击上传
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="关键字" name="keyword">
          <a-input v-model:value="form.keyword" maxlength="100"/>
        </a-form-item>
        <a-form-item label="简介" name="introduction">
          <a-textarea v-model:value="form.introduction" maxlength="255" :rows="4"/>
        </a-form-item>
        <a-form-item label="必选项目" name="requireItemList">
          <a-select mode="multiple" v-model:value="form.requireItemList" :allowClear="true" placeholder="请选择">
            <a-select-option v-for="item in itemData" :key="item.examItemId">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="可选项目" name="optionalItemList">
          <a-select mode="multiple" v-model:value="form.optionalItemList" :allowClear="true" placeholder="请选择">
            <a-select-option v-for="item in itemData" :key="item.examItemId">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="体检须知" name="notice">
          <quill-editor v-model:value="form.notice" :options="quillEditorOption" @change="onEditorChange($event)">
          </quill-editor>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";

export default {
  name: 'ExamSuiteModel',
  mixins: [CommonMixin],
  components: {},
  watch: {
    visible() {
      let that = this;
      if (that.visible) {
        api.exam.item.validData().then(res => {
          if (res.code != that.apiStatus.success) {
            that.$message.error("体检项目加载失败");
            return;
          }
          that.itemData = res.data;
        }).catch(() => {
          that.$message.error("体检项目加载失败");
        });
      }
    }
  },
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      quillEditorOption: this.$store.state.app.quillEditorOption,
      itemData: [],
      form: {
        examSuiteId: null,
        name: '',
        icon: '',
        keyword: '',
        introduction: '',
        notice: '',
        requireItemList: [],
        optionalItemList: [],
      },
      rules: {
        name: [
          {required: true, message: '请输入名称！', trigger: 'blur'},
        ],
        icon: [
          {required: true, message: '请选择图标！', trigger: 'blur'},
        ],
        requireItemList: [
          {required: true, message: '请选择必选项目！', trigger: 'blur'},
        ],
      },
      // 上传文件
      action: window._CONFIG["apiBaseUrl"] + api.exam.suite.uploadIcon,
      fileList: [],
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_exam_suite_list");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    onEditorChange({html}) {
      this.form.notice = html;
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';
      let data = {...record};
      data.requireItemList = [];
      data.optionalItemList = [];
      data.suiteItemList.forEach(function (item) {
        if (item.requireFlag == 1) {
          data.requireItemList.push(item.examItemId);
        } else {
          data.optionalItemList.push(item.examItemId);
        }
      });
      this.form = data;
    },
    okCallback() {
      this.$emit('ok');
    },
    handleFileChange(info) {
      if (info.file.status === "done") {
        this.form.icon = info.file.response;
      } else if (info.file.status === "error") {
        let message = "上传失败！";
        if (info.file.response && info.file.response.message) {
          message = info.file.response.message;
        }
        this.$message.error(message);
      }
      this.fileList = info.fileList;
    },
    formData() {
      let data = {...this.form};
      let suiteItemList = [];
      // 必选项目
      this.form.requireItemList.forEach(function (item) {
        suiteItemList.push({
          examItemId: item,
          requireFlag: 1
        });
      });
      // 可选项目
      this.form.optionalItemList.forEach(function (item) {
        suiteItemList.push({
          examItemId: item,
          requireFlag: 0
        });
      });
      data.suiteItemList = suiteItemList;
      return data;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.exam.suite.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>

<style scoped>
.avatar-uploader {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  padding-top: 40px;
  cursor: pointer;
}
</style>