import constant from "@/store/admin/constant";

export default {
    data() {
        return {
            // 接口状态
            apiStatus: this.$store.state.app.apiStatus,
            // 数据字典
            dictData: this.$store.state.operator.dictData,
            // 分页对象
            pagination: {...this.$store.state.app.pagination},
            // 结果标识
            resultFlagData: [{code: 1, text: "有数据"}, {code: 0, text: "无数据"}],
            // 文件上传
            uploadHeaders: {},
        };
    },
    methods: {
        // 权限判断
        hasPermission(id) {
            let flag = false;
            this.$store.state.operator.resourceList.forEach(function (item) {
                if (item.id == id) {
                    flag = true;
                }
            });
            return flag;
        },

        // select搜索
        selectFilterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        // 文件上传
        beforeUpload() {
            this.uploadHeaders[constant.HEADER_TOKEN] = this.$store.state.operator.token;
        },

        // 拖动表格列
        handleResizeColumn: (width, column) => {
            column.width = width;
        },

    }
};