import {createStore} from 'vuex';

import app from './modules/app';
import operator from './modules/operator';

export default createStore({
    modules: {
        app,
        operator,
    },
    state: {},
    mutations: {},
    actions: {},
    getters: {},
});
