<template>
  <a-modal :getContainer="getContainer" v-model:visible="visible" :title="title" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :afterClose="afterClose" :width="500">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item v-if="!form.id" label="模式" name="type">
          <a-select v-model:value="form.type" style="width:260px;">
            <a-select-option v-for="item in typeData" :key="item.id" :value="item.id">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="form.id" label="模式" name="mode">
          <a-input v-model:value="form.mode" readonly style="width:260px;"/>
        </a-form-item>
        <a-form-item v-if="form.type != 1" label="日期" name="date">
          <a-date-picker v-model:value="form.date" style="width:260px;"/>
        </a-form-item>
        <a-form-item v-if="form.type != 3" label="上午" name="amRange">
          <a-time-range-picker v-model:value="form.amRange" format="HH:mm" style="width:260px;"/>
        </a-form-item>
        <a-form-item v-if="form.type != 3" label="下午" name="pmRange">
          <a-time-range-picker v-model:value="form.pmRange" format="HH:mm" style="width:260px;"/>
        </a-form-item>
      </a-form>
      <div class="form-bootom-button">
        <a-button type="button" @click="close">取消</a-button>
        <a-button type="primary" @click="handleSubmit" style="margin-left: 10px">确定</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import dayjs from 'dayjs';

export default {
  name: 'TimeModel',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      title: '',
      typeData: [{id: 2, text: "指定工作日"}, {id: 3, text: "指定休息日"}],
      form: {
        id: null,
        date: null,
        amRange: null,
        pmRange: null
      },
      rules: {
        type: [
          {required: true, message: '请选择模式！', trigger: 'blur'},
        ],
        date: [
          {required: true, message: '请选择日期！', trigger: 'blur'},
        ],
        amRange: [
          {required: true, message: '请选择上午时间段！', trigger: 'blur'},
        ],
        pmRange: [
          {required: true, message: '请选择下午时间段！', trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    getContainer() {
      return document.getElementById("comptainer_register_time_config");
    },
    close() {
      this.visible = false;
    },
    afterClose() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.form.clearValidate();
    },
    add() {
      this.visible = true;
      this.title = "新增";
    },
    edit(record) {
      this.visible = true;
      this.title = '编辑';

      let data = {...record};
      // 日期反显
      if (data.date) {
        data.date = dayjs(data.date, "YYYY-MM-DD");
      }
      if (data.amTime) {
        let at = data.amTime.split("~");
        data.amRange = [dayjs(at[0].trim(), "HH:mm"), dayjs(at[1].trim(), "HH:mm")];
      }
      if (data.pmTime) {
        let pt = data.pmTime.split("~");
        data.pmRange = [dayjs(pt[0].trim(), "HH:mm"), dayjs(pt[1].trim(), "HH:mm")];
      }
      this.form = data;
    },
    okCallback() {
      this.$emit('ok');
    },
    formData() {
      let data = {...this.form};
      data.date = data.date ? data.date.format("YYYY-MM-DD") : null;
      if (data.amRange && data.amRange.length > 0 && data.amRange[0] && data.amRange[1]) {
        data.amTime = data.amRange[0].format("HH:mm") + "~" + data.amRange[1].format("HH:mm");
      }
      if (data.pmRange && data.pmRange.length > 0 && data.pmRange[0] && data.pmRange[1]) {
        data.pmTime = data.pmRange[0].format("HH:mm") + "~" + data.pmRange[1].format("HH:mm");
      }
      return data;
    },
    handleSubmit() {
      let that = this;
      that.$refs.form.validate().then(() => {
        let param = that.formData();
        that.spinning = true;
        api.register.timeConfig.save(param).then(res => {
          that.spinning = false;
          if (res.code != that.apiStatus.success) {
            that.$message.error(res.message);
            return;
          }
          that.$message.success(that.apiStatus.defaultSuccMsg);
          that.close();
          that.okCallback();
        }).catch(() => {
          that.spinning = false;
          that.$message.error(that.apiStatus.defaultErrMsg);
        });
      });
    }
  }
}
</script>