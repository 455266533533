<template>
  <a-layout class="page-layout">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <h1 class="logo-title" v-if="!collapsed">管理后台</h1>
      </div>
      <a-menu theme="dark" mode="inline" :selectedKeys="selectedMenu" @select="handleMenuSelect">
        <template v-for="fmenu in $store.state.operator.menuList" :key="fmenu.id">
          <a-menu-item :key="fmenu.id" v-if="fmenu.isLeaf == 1">
            <span>{{ fmenu.name }}</span>
          </a-menu-item>
          <a-sub-menu :key="fmenu.id" :title="fmenu.name" v-else>
            <template v-for="smenu in fmenu.children" :key="smenu.id">
              <a-menu-item :key="smenu.id" v-if="smenu.isLeaf == 1">{{ smenu.name }}</a-menu-item>
              <a-sub-menu :key="smenu.id" :title="smenu.name" v-else>
                <template v-for="tmenu in smenu.children" :key="tmenu.id">
                  <a-menu-item :key="tmenu.id" v-if="tmenu.isLeaf == 1">{{ tmenu.name }}</a-menu-item>
                </template>
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="layout-header">
        <MenuUnfoldOutlined
            v-if="collapsed"
            class="trigger"
            @click="() => (collapsed = !collapsed)"/>
        <MenuFoldOutlined v-else class="trigger" @click="() => (collapsed = !collapsed)"/>
        <div style="padding: 0 20px;">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()" style="color: #002140;">
              {{ $store.state.operator.operator.realName }}
              <DownOutlined/>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click="baseInfo">基本资料</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="updatePassword">修改密码</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a href="javascript:;" @click="logout" style="color: #002140;padding-left: 20px;">退出</a>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-header class="layout-navigation">
          <a-menu mode="horizontal" :selectedKeys="selectedNavigation" @select="handleNavigationSelect">
            <a-menu-item class="layout-navigation-menu" v-for="menu in navigationData" :key="menu.id">
              <span>{{ menu.name }}</span>
              <div class="layout-navigation-close" @click.stop="handleNavigationClose(menu.id)">X</div>
            </a-menu-item>
          </a-menu>
        </a-layout-header>
        <a-layout-content class="layout-content">
          <LayoutContent ref="layoutContent"/>
          <BaseInfo ref="baseInfo"/>
          <UpdatePassword ref="updatePassword"/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import {DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons-vue';
import LayoutContent from "./LayoutContent";
import BaseInfo from "@/view/admin/operator/BaseInfo";
import UpdatePassword from "@/view/admin/operator/UpdatePassword";
import constant from "@/store/admin/constant";

export default {
  name: 'PageLayout',
  components: {
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    LayoutContent,
    BaseInfo,
    UpdatePassword
  },
  data() {
    return {
      collapsed: false,
      selectedMenu: [],
      navigationData: [],
      selectedNavigation: []
    };
  },
  methods: {
    handleMenuSelect(e) {
      this.selectedMenu = [e.key];
      let isSelected = false;
      this.navigationData.forEach(function (item) {
        if (item.id == e.key) {
          isSelected = true;
        }
      });
      if (isSelected) {
        this.openNavigationItem(e.key);
      } else {
        this.createNavigationItem(e.key);
      }
    },
    createNavigationItem(id) {
      let menu = null;
      this.$store.state.operator.resourceList.forEach(function (item) {
        if (item.id == id) {
          menu = item;
        }
      });
      this.navigationData.push(menu);
      this.openNavigationItem(id);
    },
    openNavigationItem(id) {
      this.selectedNavigation = [id];
      this.$refs.layoutContent.changeMenu(id);
    },
    handleNavigationSelect(e) {
      this.openNavigationItem(e.key);
      this.selectedMenu = [e.key];
    },
    handleNavigationClose(id) {
      let data = [];
      this.navigationData.forEach(function (item) {
        if (item.id != id) {
          data.push(item);
        }
      });
      this.navigationData = data;
      // 选中第一个
      if (data.length > 0) {
        this.selectedMenu = [data[0].id];
        this.selectedNavigation = [data[0].id];
        this.openNavigationItem(data[0].id);
      } else {
        this.clearNavigation();
      }
    },
    clearNavigation() {
      this.selectedMenu = [];
      this.selectedNavigation = [];
      this.navigationData = [];
      this.$refs.layoutContent.clearNavigation();
    },
    logout() {
      let that = this;
      that.$confirm({
        title: '确定退出?',
        content: '',
        onOk() {
          that.$store.commit("CLEAR_LOGIN_DATA");
          sessionStorage.removeItem(constant.LOGIN_DATA);
        }
      });
    },
    baseInfo() {
      this.$refs.baseInfo.show(this.$store.state.operator.operator);
    },
    updatePassword() {
      this.$refs.updatePassword.edit(this.$store.state.operator.operator.operatorId);
    },
  }
};
</script>
<style scoped>
.page-layout {
  height: 100%;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 64px;
  line-height: 64px;
  background-color: #002140;
  padding-left: 24px;
  overflow: hidden;
}

.logo-img {
  display: inline-block;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: inline-block;
  color: #ffffff;
  font-size: 20px;
  margin: 0 0 0 8px;
  vertical-align: middle;
}

.layout-header {
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.layout-navigation {
  height: 50px;
  line-height: 50px;
  background: #fff;
  padding: 0;
  border: 1px solid #e8e8e8;
}

.layout-navigation-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.layout-navigation-menu:hover .layout-navigation-close {
  display: block;
}
</style>
