import '@/config';

import {createApp} from 'vue';
import Antd from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import VueQuillEditor from 'vue-quill-editor/src';
import App from './App.vue';
import store from '@/store/admin';
import constant from "@/store/admin/constant";

import 'ant-design-vue/dist/antd.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import '@/assets/css/common.css';


// 刷新页面加载数据
(function () {
    let data = sessionStorage.getItem(constant.LOGIN_DATA);
    if (data) {
        store.commit('SET_LOGIN_DATA', JSON.parse(data));
    }
})();

const app = createApp(App);
app.config.productionTip = false;
for (let key in icons) {
    app.component(key, icons[key]);
}
app.use(store).use(Antd).use(VueQuillEditor).mount('#app');