<template>
  <a-modal v-model:visible="visible" title="基本资料" @cancel="close" :keyboard="true"
           :maskClosable="true" :footer="null" :width="800">
    <a-spin :spinning="spinning">
      <a-form ref="form" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="手机号码" name="mobilePhone">
          <a-input v-model:value="form.mobilePhone" :maxlength="11" readonly/>
        </a-form-item>
        <a-form-item label="姓名" name="realName">
          <a-input v-model:value="form.realName" readonly/>
        </a-form-item>
        <a-form-item label="性别" name="gender">
          <a-select v-model:value="form.gender" disabled>
            <a-select-option v-for="item in dictData.genderData" :key="item.code" :value="item.code">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="身份证号" name="cardNo">
          <a-input v-model:value="form.cardNo" readonly/>
        </a-form-item>
      </a-form>

      <div class="form-bootom-button">
        <a-button type="button" @click="close">关闭</a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import CommonMixin from "@/mixin/admin/CommonMixin";

export default {
  name: 'BaseInfo',
  mixins: [CommonMixin],
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      form: {
        operatorId: null,
        mobilePhone: "",
        realName: "",
        gender: null,
        cardNo: "",
        roleId: null
      },
    }
  },
  methods: {
    close() {
      this.visible = false;
    },
    show(record) {
      this.visible = true;
      this.form = {...record};
    },
  }
}
</script>