<template>
  <!-- 用户配置 -->
  <div class="comptainer" id="comptainer_operator_config" v-show="current=='operator_config'">
    <OperatorConfig v-if="menuLoad('operator_config')"/>
  </div>

  <!-- 挂号列表 -->
  <div class="comptainer" id="comptainer_register_list" v-show="current=='register_list'">
    <RegisterList v-if="menuLoad('register_list')"/>
  </div>

  <!-- 就诊列表 -->
  <div class="comptainer" id="comptainer_visit_list" v-show="current=='visit_list'">
    <VisitList v-if="menuLoad('visit_list')"/>
  </div>

  <!-- 挂号表导出 -->
  <div class="comptainer" id="comptainer_register_export_list" v-show="current=='register_export_list'">
    <RegisterExportList v-if="menuLoad('register_export_list')"/>
  </div>

  <!-- 挂号时间配置 -->
  <div class="comptainer" id="comptainer_register_time_config" v-show="current=='register_time_config'">
    <RegisterTimeConfig v-if="menuLoad('register_time_config')"/>
  </div>

  <!-- 科室配置 -->
  <div class="comptainer" id="comptainer_department_config" v-show="current=='department_config'">
    <DepartmentConfig v-if="menuLoad('department_config')"/>
  </div>

  <!-- 体检项目 -->
  <div class="comptainer" id="comptainer_exam_item_list" v-show="current=='exam_item_list'">
    <ExamItemList v-if="menuLoad('exam_item_list')"/>
  </div>

  <!-- 体检套餐 -->
  <div class="comptainer" id="comptainer_exam_suite_list" v-show="current=='exam_suite_list'">
    <ExamSuiteList v-if="menuLoad('exam_suite_list')"/>
  </div>

  <!-- 体检时间配置 -->
  <div class="comptainer" id="comptainer_exam_time_config" v-show="current=='exam_time_config'">
    <ExamTimeConfig v-if="menuLoad('exam_time_config')"/>
  </div>

  <!-- 体检预约 -->
  <div class="comptainer" id="comptainer_exam_info_list" v-show="current=='exam_info_list'">
    <ExamInfoList v-if="menuLoad('exam_info_list')"/>
  </div>
</template>

<script>
import OperatorConfig from "@/view/admin/operator/OperatorConfig";
import RegisterList from "@/view/admin/register/RegisterList";
import VisitList from "@/view/admin/register/VisitList";
import RegisterExportList from "@/view/admin/register/RegisterExportList";
import RegisterTimeConfig from "@/view/admin/register/time/TimeConfig";
import DepartmentConfig from "@/view/admin/department/DepartmentConfig";
import ExamItemList from "@/view/admin/exam/item/ExamItemList";
import ExamSuiteList from "@/view/admin/exam/suite/ExamSuiteList";
import ExamTimeConfig from "@/view/admin/exam/time/TimeConfig";
import ExamInfoList from "@/view/admin/exam/info/ExamInfoList";

export default {
  name: 'LayoutContent',
  components: {
    OperatorConfig,
    RegisterList,
    VisitList,
    RegisterExportList,
    RegisterTimeConfig,
    DepartmentConfig,
    ExamItemList,
    ExamSuiteList,
    ExamTimeConfig,
    ExamInfoList
  },
  data() {
    let menu = [];
    let current = '';
    if (this.$store.state.operator.menuList && this.$store.state.operator.menuList.length > 0) {
      menu.push(this.$store.state.operator.menuList[0].id);
      current = this.$store.state.operator.menuList[0].id;
    }
    return {
      menu: menu,
      current: current
    };
  },
  methods: {
    changeMenu(id) {
      this.current = id;
      if (this.menu.indexOf(id) == -1) {
        this.menu.push(id);
      }
    },
    menuLoad(id) {
      if (this.menu.indexOf(id) > -1) {
        return true;
      }
      return false;
    },
    clearNavigation() {
      this.menu = [];
      this.current = '';
    }
  }
};
</script>
