<template>
  <a-config-provider :locale="locale">
    <PageLayout ref="pageLayout" v-if="$store.state.operator.logged"/>
    <Login v-if="!$store.state.operator.logged"/>

  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import PageLayout from "@/view/admin/layout/PageLayout";
import Login from "@/view/admin/login/Login";

dayjs.locale('zh-cn');

export default {
  name: 'App',
  components: {PageLayout, Login},
  data() {
    return {
      locale: zhCN,
    };
  },
  methods: {},
}
</script>
