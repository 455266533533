<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="预约流水号" name="code">
        <a-input v-model:value="form.code" class="query-condition"/>
      </a-form-item>
      <a-form-item label="就诊人姓名" name="patientName">
        <a-input v-model:value="form.patientName" class="query-condition"/>
      </a-form-item>
      <a-form-item label="就诊人证件号码" name="cardNo">
        <a-input v-model:value="form.cardNo" class="query-condition"/>
      </a-form-item>
      <a-form-item label="手机号码" name="mobilePhone">
        <a-input v-model:value="form.mobilePhone" class="query-condition"/>
      </a-form-item>
      <a-form-item label="预约就诊日期" name="appointmentDate">
        <a-date-picker v-model:value="form.appointmentDate"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="examItemId"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'requireItemList'">
            <div v-for="item in record.requireItemList" :key="item.examItemId">
              {{ item.name }} / {{ item.money }}元
            </div>
          </template>
          <template v-if="column.dataIndex === 'optionalItemList'">
            <div v-for="item in record.optionalItemList" :key="item.examItemId">
              {{ item.name }} / {{ item.money }}元
            </div>
          </template>
          <template v-if="column.dataIndex === 'report'">
            <div v-if="record.reportTime">{{ record.reportTime }}</div>
            <a v-if="record.reportUrl" href="javascript:;" @click="viewReport(record.reportUrl)">查看报告</a>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a-popconfirm v-if="record.status == 'PAYED'" :title="'就诊人'+record.patientName+'签到?'"
                          @confirm="() => handleSign(record.examInfoId)">
              <a style="margin-left: 10px;">签到</a>
            </a-popconfirm>
            <a-popconfirm v-if="record.status == 'PAYED'" @confirm="() => refund(record.examInfoId)">
              <template v-slot:title>
                <a-input v-model:value="refundReason" maxlength="80" style="width: 300px;" placeholder="请输入退款原因"/>
              </template>
              <a style="margin-left: 10px;">退款</a>
            </a-popconfirm>
            <a v-if="record.status == 'SIGNED'" @click="handleUpload(record.examInfoId)" style="margin-left: 10px;">
              <span>上传报告</span>
            </a>
          </template>
        </template>

      </a-table>
    </div>

    <UploadModal ref="uploadModal" @ok="uploadModalOk"/>
  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import UploadModal from './UploadModal';


const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '预约流水号',
    dataIndex: 'code',
    width: 150,
    align: 'center',
  },
  {
    title: '就诊人姓名',
    dataIndex: 'patientName',
    width: 100,
    align: 'center',
  },
  {
    title: '就诊人证件号码',
    dataIndex: 'cardNo',
    width: 150,
    align: 'center',
  },
  {
    title: '手机号码',
    dataIndex: 'mobilePhone',
    width: 120,
    align: 'center',
  },
  {
    title: '体检套餐',
    dataIndex: 'examSuiteName',
    width: 200,
    align: 'center',
  },
  {
    title: '必选项目',
    dataIndex: 'requireItemList',
    width: 200,
    align: 'left',
  },
  {
    title: '可选项目',
    dataIndex: 'optionalItemList',
    width: 200,
    align: 'left',
  },
  {
    title: '总金额(元)',
    dataIndex: 'sumMoney',
    width: 100,
    align: 'center',
  },
  {
    title: '预约就诊时间',
    dataIndex: 'appointmentTime',
    width: 180,
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'statusName',
    width: 100,
    align: 'center',
  },
  {
    title: '预约成功时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center',
  },
  {
    title: '签到时间',
    dataIndex: 'signTime',
    width: 150,
    align: 'center',
  },
  {
    title: '体检报告',
    dataIndex: 'report',
    width: 150,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    align: 'center',
  }
];

export default {
  name: 'ExamInfoList',
  mixins: [CommonMixin],
  components: {UploadModal},
  data() {
    return {
      form: {
        code: '',
        patientName: '',
        cardNo: '',
        mobilePhone: '',
        appointmentDate: null
      },
      columns,
      visible: false,
      dataSource: [],
      loading: false,
      refundReason: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      let parm = {...that.form};
      parm.date = parm.date ? parm.date.format("YYYY-MM-DD") : null;
      parm.pageNum = that.pagination.current;
      parm.pageSize = that.pagination.pageSize;
      api.exam.info.list(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    refund(examInfoId) {
      let that = this;
      let parm = {
        examInfoId: examInfoId,
        refundReason: that.refundReason
      };
      api.exam.info.refundOrder(parm).then(res => {
        that.refundReason = "";
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(res.message);
        that.loadData();
      }).catch(() => {
        that.refundReason = "";
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleSign(examInfoId) {
      let that = this;
      api.exam.info.signIn(examInfoId).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success("签到成功");
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    viewReport(reportUrl) {
      if (reportUrl) {
        window.open(reportUrl);
      }
    },
    handleUpload(examInfoId) {
      this.$refs.uploadModal.show(examInfoId);
    },
    uploadModalOk() {
      this.loadData();
    },
  }
};
</script>
