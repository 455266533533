<template>
  <div class="comptent">
    <a-form layout="inline" ref="form" :model="form">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="form.name" class="query-condition"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="searchQuery" style="margin-left: 21px">
          <template #icon>
            <SearchOutlined/>
          </template>
          查询
        </a-button>
        <a-button @click="searchReset" style="margin-left: 8px">
          <template #icon>
            <ReloadOutlined/>
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="table-wrapper">
      <div class="table-tools">
        <a-button type="primary" @click="add">
          <template #icon>
            <PlusOutlined/>
          </template>
          新增
        </a-button>
      </div>

      <a-table
          bordered
          :columns="columns"
          :data-source="dataSource"
          rowKey="examSuiteId"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
          size="middle"
          :scroll="{ x: 'max-content'}">

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'icon'">
            <img :src="record.icon" style="width:100px;height:100px;"/>
          </template>
          <template v-if="column.dataIndex === 'notice'">
            <div v-html="record.notice"/>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <a @click="edit(record)">编辑</a>
          </template>
        </template>

      </a-table>

      <ExamSuiteModel ref="examSuiteModel" @ok="loadData"/>
    </div>

  </div>
</template>

<script>
import api from "@/api/admin";
import CommonMixin from "@/mixin/admin/CommonMixin";
import ExamSuiteModel from "./ExamSuiteModel";

const columns = [
  {
    title: '序号',
    dataIndex: 'rowIndex',
    align: 'center',
    width: 70,
    customRender: ({index}) => {
      return index + 1;
    }
  },
  {
    title: '名称',
    dataIndex: 'name',
    width: 150,
    align: 'center',
  },
  {
    title: '图标',
    dataIndex: 'icon',
    width: 100,
    align: 'center',
  },
  {
    title: '关键字',
    dataIndex: 'keyword',
    width: 200,
    align: 'center',
  },
  {
    title: '简介',
    dataIndex: 'introduction',
    width: 200,
    align: 'left',
  },
  {
    title: '体检须知',
    dataIndex: 'notice',
    width: 300,
    align: 'left',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 180,
    align: 'center',
  },
];

export default {
  name: 'ExamSuiteList',
  mixins: [CommonMixin],
  components: {
    ExamSuiteModel,
  },
  data() {
    return {
      form: {
        name: '',
      },
      columns,
      visible: false,
      dataSource: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    searchQuery() {
      this.loadData();
    },
    searchReset() {
      this.$refs.form.resetFields();
    },
    loadData() {
      let that = this;
      that.loading = true;
      let parm = {
        "pageNum": that.pagination.current,
        "pageSize": that.pagination.pageSize,
        "name": that.form.name
      };
      api.exam.suite.list(parm).then(res => {
        that.loading = false;
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.dataSource = res.data.list;
        that.pagination.total = res.data.total;
      }).catch(() => {
        that.loading = false;
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    handleTableChange(pagination) {
      this.pagination = pagination;
      this.loadData();
    },
    add() {
      this.$refs.examSuiteModel.add();
    },
    edit(record) {
      this.$refs.examSuiteModel.edit(record);
    },
    enable(operatorId) {
      let that = this;
      api.operator.enable(operatorId).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
    disable(operatorId) {
      let that = this;
      api.operator.disable(operatorId).then(res => {
        if (res.code != that.apiStatus.success) {
          that.$message.error(res.message);
          return;
        }
        that.$message.success(that.apiStatus.defaultSuccMsg);
        that.loadData();
      }).catch(() => {
        that.$message.error(that.apiStatus.defaultErrMsg);
      });
    },
  }
};
</script>
